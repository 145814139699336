(function($) {
    "use strict"; // Start of use strict

    $ = jQuery;

    var mainMenu = (function () {
        "use strict"; // Start of use strict

        //Globals
        var $menu = $('[js-menu]'),
            $aside = $('[js-menu-list]'),
            $overlay = $('[js-menu-overlay]');

        function _toggleClass() {
            $aside.toggleClass('is-open');
            $overlay.toggleClass('is-visible');
            $('body').toggleClass('has-nav-open');
        }

        $menu.on('click', function(event) {
            event.preventDefault();
            _toggleClass();
        });

        $overlay.on('click', function(event) {
            event.preventDefault();
            _toggleClass();
        });

        $(document).keyup(function(event) {
            if (event.keyCode == 27) {
                _toggleClass();
            }
        });

    })();

    $(document).ready(function() {
        $('.popup-youtube').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,

            fixedContentPos: false
        });
    });


})(jQuery); // End of use strict


